import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'
import moment from 'moment'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const convertDate2Str = (date) => {
  let d = date.getDate()
  let m = date.getMonth() + 1

  if (d < 10) {
    d = '0' + d
  }
  if (m < 10) {
    m = '0' + m
  }
  return `${d}.${m}.${date.getFullYear()}`
}

export const convert2NormalDate = (date) => {
  if (!date) {
    return null
  }
  let arr = date.split('.')
  if (arr.length != 3 || date.length != 10) {
    return null
  }
  return `${arr[2]}-${arr[1]}-${arr[0]}`
}

export const convert2NormalDateTime = (datetime) => {
  if (!datetime) {
    return null
  }
  let date = convert2NormalDate(datetime.substring(0, 10))
  return `${date} ${datetime.substring(11, 16)}`
}

export const subtractDate = (date1, date2, unit, bDecimal = false) => {
  let startDateStr = convert2NormalDate(date1)
  let endDateStr = convert2NormalDate(date2)
  if (!startDateStr || !endDateStr) {
    return ''
  }
  let startDate = moment(startDateStr)
  let endDate = moment(endDateStr)
  return endDate.diff(startDate, unit, bDecimal).toFixed(bDecimal ? 2 : 0)
}

export const getDiff = (obj1, obj2) => {
  let diffObj = {}

  Object.keys(obj1).forEach(key => {
    let v1 = obj1[key], v2 = obj2[key]
    if (v1 != v2) {
      diffObj[key] = v1
    }
  })

  return diffObj
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }
export const dateMask = (v) => {
  let numbers = v.replace(/[^0-9]/g, '')
  let day = numbers.substring(0, 2), month = numbers.substring(2, 4), year = numbers.substring(4, 8)
  let isLeapYear = (y) => y != 0 && y % 4 == 0 && (y % 100 != 0 || y % 400 == 0)
  let days = [0, 31, 28+isLeapYear(+year), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  
  if (day.length) {
    if (month.length == 2 && +month < 13) {
      if (+day > days[+month]) {
        day = '' + days[+month]
      }
    } else {
      if (+day > 31) {
        day = '31'
      }
    }

    if (day.length == 2 && +day == 0) {
      day = '01'
    }
  }

  if (month.length) {
    if (month.length == 2 && +month == 0) {
      month = '01'
    }
    if (+month > 12) {
      month = '12'
    }
  }

  let result = []
  result.push(...day)
  if (day.length == 2) {
    result.push('.')
  }
  if (month.length) {
    result.push(...month)
    if (month.length == 2) {
      result.push('.')
    }
  }
  if (year.length) {
    result.push(...year)
  }
  
  return result
}

export const getMeldScoreForDischargeAndFollowup = (creatinin, bilirubin, inr) => {
  creatinin = creatinin || 1
  bilirubin = bilirubin || 1
  inr = inr || 1

  return (10 * (0.957 * Math.log(creatinin) + 0.378 * Math.log(bilirubin) + 1.12 * Math.log(inr) + 0.643)).toFixed(2)
}

export const getMeldScoreForRiskProcedure = (creatinin, bilirubin, inr, within_last_7_days) => {
  creatinin = creatinin || 1
  bilirubin = bilirubin || 1
  inr = inr || 1

  if (within_last_7_days == 'yes') {
    creatinin = 4
  }

  return (10 * (0.957 * Math.log(creatinin) + 0.378 * Math.log(bilirubin) + 1.12 * Math.log(inr) + 0.643)).toFixed(2)
}

export const getCRS = (patient, mort_vs_morb) => {
  let { procedure_date, date_of_birth, gender, status_preop_clinical_nyha
    , status_preop_copd_gold_stage, status_preop_stroke, status_preop_dialysis
    , previous_cardiac_surgery, status_preop_urgency } = patient
  let sum_score_mortality = 0
  let sum_score_morbidity = 0

  // age (at surgery)
  let val_age = subtractDate(date_of_birth, procedure_date, 'year', true)

  if (val_age) {
    if (val_age < 50) {
      sum_score_mortality += 0
      sum_score_morbidity += 0
    } else if (val_age <= 59) {
      sum_score_mortality += 0
      sum_score_morbidity += 1
    } else if (val_age < 70) {
      sum_score_mortality += 2
      sum_score_morbidity += 2
    } else if (val_age >= 70) {
      sum_score_mortality += 3
      sum_score_morbidity += 2
    }
  }

  // gender
  if (gender == 'female') {
    sum_score_mortality += 1
    sum_score_morbidity += 1
  }

  // nyha
  let val_nyha = 0
  let res_nyha = 0

  if (!status_preop_clinical_nyha) {
    res_nyha = 0
  } else {
    val_nyha = status_preop_clinical_nyha
    switch (val_nyha) {
      case 'III':
        sum_score_mortality += 2
        sum_score_morbidity += 2
        break
      case 'IV':
        sum_score_mortality += 3
        sum_score_morbidity += 3
        break
      default:
        res_nyha = 0
    }
  }

  // sum_score += res_nyha

  // COPD gold stage
  switch (status_preop_copd_gold_stage) {
    case "I FEV1 % vom Soll: ≥ 80%":
      sum_score_mortality += 1
      sum_score_morbidity += 1
      break
    case "II FEV1 % vom Soll: 50-79%":
      sum_score_mortality += 1
      sum_score_morbidity += 1
      break
    case "III FEV1 % vom Soll: 30-49%":
      sum_score_mortality += 3
      sum_score_morbidity += 1
      break
    case "IV FEV1 % vom Soll: <30 %":
      sum_score_mortality += 3
      sum_score_morbidity += 1
      break
  }

  // preop stroke
  if (status_preop_stroke == 'yes') {
    sum_score_mortality += 2
    sum_score_morbidity += 2
  }

  // preop dialysis
  if (status_preop_dialysis == 'yes') {
    sum_score_mortality += 4
    sum_score_morbidity += 1
  }

  // preop dialysis
  if (status_preop_dialysis == 'yes') {
    sum_score_mortality += 4
    sum_score_morbidity += 1
  }

  // reoperation
  if (previous_cardiac_surgery == 'yes') {
    sum_score_mortality += 2
    sum_score_morbidity += 3
  }

  // status emergent (or salvage)
  switch (status_preop_urgency) {
    case 'emergent':
      sum_score_mortality += 4
      sum_score_morbidity += 9
      break
    case 'salvage':
      sum_score_mortality += 4
      sum_score_morbidity += 9
      break
  }

  if (mort_vs_morb == 'mortality') {
    return sum_score_mortality
  } else if (mort_vs_morb == 'morbidity') {
    return sum_score_morbidity
  } else {
    return ''
  }
}

export const getCRSProbabiliity = (crs_score, mort_vs_morb) => {
  let probability_mortality = 0.0
  let probability_morbidity = 0.0

  switch (crs_score) {
    case 0:
      probability_mortality = 0.02
      probability_morbidity = 0.13
      break
    case 1:
      probability_mortality = 0.03
      probability_morbidity = 0.14
      break
    case 2:
      probability_mortality = 0.03
      probability_morbidity = 0.19
      break
    case 3:
      probability_mortality = 0.04
      probability_morbidity = 0.18
      break
    case 4:
      probability_mortality = 0.06
      probability_morbidity = 0.26
      break
    case 5:
      probability_mortality = 0.07
      probability_morbidity = 0.29
      break
    case 6:
      probability_mortality = 0.09
      probability_morbidity = 0.37
      break
    case 7:
      probability_mortality = 0.12
      probability_morbidity = 0.42
      break
    case 8:
      probability_mortality = 0.15
      probability_morbidity = 0.50
      break
    case 8:
      probability_mortality = 0.15
      probability_morbidity = 0.
      break
    case 9:
      probability_mortality = 0.21
      probability_morbidity = 0.59
      break
    default:
      if (crs_score >= 10) {
        probability_mortality = 0.34
        probability_morbidity = 0.71
      }
      break
  }

  if (mort_vs_morb == 'mortality') {
    return probability_mortality
  } else if (mort_vs_morb == 'morbidity') {
    return probability_morbidity
  } else {
    return ''
  }
}

export const getTriSCORE = (patient, mort_vs_score) => {
  let { date_of_birth, procedure_date, status_preop_clinical_nyha, status_preop_right_heart_failure
      , risk_scores_preop_TriSCORE_daily_dose_furosemid_gt_125mg
      , status_preop_renal_failure_grade, preop_lab_bilirubin
      , preop_echo_LVEF
      , risk_scores_preop_TriSCORE_moderate_severe_RV_dysfunction } = patient
  let sum_score = 0
  let tri_score = 0

  // Age > 70 years
  let res_age = 0
  let val_age = subtractDate(date_of_birth, procedure_date, 'year', true)

  if (!val_age) {
    res_age = 0
  } else {
    if (val_age >= 70) {
      res_age = 0.5
      tri_score += 1
    } else {
      res_age = 0
    }
  }

  sum_score += res_age

  // NYHA functional class III-IV
  let val_nyha = 0
  let res_nyha = 0

  if (!status_preop_clinical_nyha) {
    res_nyha = 0
  } else {
    val_nyha = status_preop_clinical_nyha

    switch (val_nyha) {
      case 'III':
        res_nyha = 0.57
        tri_score += 1
        break
      case 'IV':
        res_nyha = 0.57
        tri_score += 1
        break
      default:
        res_nyha = 0
    }
  }
  sum_score += res_nyha

  // Right-sided heart failure signs
  let val_right_heart_failure = 0
  let res_right_heart_failure = 0

  if (!status_preop_right_heart_failure) {
    res_right_heart_failure = 0
  } else {
    val_right_heart_failure = status_preop_right_heart_failure

    switch (val_right_heart_failure) {
      case 'yes':
        res_right_heart_failure = 0.96
        tri_score += 2
        break
      default:
        res_right_heart_failure = 0
    }
  }
  sum_score += res_right_heart_failure

  // Daily does of furosemide >=125mg
  let val_furosemide = 0
  let res_furosemide = 0

  if (!risk_scores_preop_TriSCORE_daily_dose_furosemid_gt_125mg) {
    res_furosemide = 0
  } else {
    val_furosemide = risk_scores_preop_TriSCORE_daily_dose_furosemid_gt_125mg

    switch (val_furosemide) {
      case 'yes':
        res_furosemide = 0.81
        tri_score += 2
        break
      default:
        res_furosemide = 0
    }
  }
  sum_score += res_furosemide

  // Glomerular filtration rate <30mL/min
  let val_GFR = 0
  let res_GFR = 0

  if (!status_preop_renal_failure_grade) {
    res_GFR = 0
  } else {
    val_GFR = status_preop_renal_failure_grade

    switch (val_GFR) {
      case 'severe GFR<50':
        res_GFR = 0.90
        tri_score += 2
        break
      default:
        res_GFR = 0
    }
  }
  sum_score += res_GFR

  // Elevated total bilirubin (1.2mg/dl)
  let val_bilirubin = 0
  let res_bilirubin = 0

  if (!preop_lab_bilirubin) {
    res_bilirubin = 0
  } else {
    val_bilirubin = preop_lab_bilirubin

    if (val_bilirubin > 1.2) {
      res_bilirubin = 1.06
      tri_score += 2
    } else {
      res_bilirubin = 0
    }
  }
  sum_score += res_bilirubin

  // LVEF <60% left ventricular ejection fraction
  let val_LVEF = 0
  let res_LVEF = 0

  if (!preop_echo_LVEF) {
    res_LVEF = 0
  } else {
    val_LVEF = preop_echo_LVEF

    if (val_LVEF < 0.6) {
      res_LVEF = 0.68
      tri_score += 1
    } else {
      res_LVEF = 0
    }
  }
  sum_score += res_LVEF

  // Moderate/severe right ventricular dysfunction
  let val_severe_RV = 0
  let res_severe_RV = 0

  if (!risk_scores_preop_TriSCORE_moderate_severe_RV_dysfunction) {
    res_severe_RV = 0
  } else {
    val_severe_RV = risk_scores_preop_TriSCORE_moderate_severe_RV_dysfunction

    switch (val_severe_RV) {
      case 'yes':
        res_severe_RV = 0.66
        tri_score += 1
        break
      default:
        res_severe_RV = 0
    }
  }
  sum_score += res_severe_RV

  // the probability is the mortality risk
  let mortality_risk = (Math.exp(sum_score) / (1 + Math.exp(sum_score))).toFixed(2)

  if (mort_vs_score == 'mortality') {
    return mortality_risk
  } else if (mort_vs_score == 'score') {
    return tri_score
  } else {
    return ''
  }
}

export const GetTriSCOREProbability = (tri_score) => {
  let probability = 0

  switch (tri_score) {
    case 0:
      probability = 0.01
      break
    case 1:
      probability = 0.02
      break
    case 2:
      probability = 0.03
      break
    case 3:
      probability = 0.05
      break
    case 4:
      probability = 0.08
      break
    case 5:
      probability = 0.14
      break
    case 6:
      probability = 0.22
      break
    case 7:
      probability = 0.34
      break
    case 8:
      probability = 0.48
      break
    default:
      if (tri_score >= 9) {
        probability = 0.65
      }
  }

  return probability
}

export const getEuroSCOREII = (patient) => {
  let { status_preop_clinical_nyha, risk_scores_preop_EuroSCORE_II_unstable_angina, status_preop_diabetes
      , date_of_birth, procedure_date, gender
      , status_preop_peripheral_arterial_disease
      , status_preop_copd
      , risk_scores_preop_EuroSCORE_II_poor_mobility
      , previous_cardiac_surgery
      , status_preop_renal_failure_grade
      , tv_disease_endocarditis_type
      , mv_disease_endocarditis_type
      , risk_scores_preop_EuroSCORE_II_critical_preop_state
      , risk_scores_preop_EuroSCORE_II_LV_function
      , risk_scores_preop_EuroSCORE_II_recent_MI
      , risk_scores_preop_EuroSCORE_II_PA_systolic_pressure
      , status_preop_urgency
      , intraop_op_tv, intraop_op_mv
      , intraop_concomitant_procedures
      , risk_scores_preop_EuroSCORE_II_surgery_thoraic_aorta } = patient
  let sum_score = 0
  
  // NYHA - New York Heart Association
  let val_nyha = 0
  let res_nyha = 0

  if (!status_preop_clinical_nyha) {
    res_nyha = 0
  } else {
    val_nyha = status_preop_clinical_nyha

    switch (val_nyha) {
      case 'II':
        res_nyha = 0.1070545
        break
      case 'III':
        res_nyha = 0.2958358
        break
      case 'IV':
        res_nyha = 0.5597929
        break
      default:
        res_nyha = 0
    }
  }
  sum_score += res_nyha

  // CCS4 - Canadian Cardiovascular Society
  let val_ccs4 = 0
  let res_ccs4 = 0

  if (!risk_scores_preop_EuroSCORE_II_unstable_angina) {
    res_ccs4 = 0
  } else {
    val_ccs4 = risk_scores_preop_EuroSCORE_II_unstable_angina

    switch (val_ccs4) {
      case 'yes':
        res_ccs4 = 0.2226147
        break
      default:
        res_ccs4 = 0
    }
  }
  sum_score += res_ccs4

  // IDDM - insulin dependent diabetis mellitus
  let val_iddm = 0
  let res_iddm = 0

  if (!status_preop_diabetes) {
    res_iddm = 0
  } else {
    val_iddm = status_preop_diabetes

    switch (val_iddm) {
      case 'yes':
        res_iddm = 0.3542749
        break
      default:
        res_iddm = 0
    }
  }
  sum_score += res_iddm

  // Age - Xi = 1 if age <= 60 else: Xi = Age-60
  let val_age = subtractDate(date_of_birth, procedure_date, 'year', true)
  let res_age = 0

  if (val_age) {
    if (val_age <= 60) {
      res_age = 0.0285181
    } else {
      res_age = 0.0285181 * (val_age - 60)
    }
  }
  sum_score += res_age

  // Female
  let val_gender = 0
  let res_gender = 0

  if (!gender) {
    res_gender = 0
  } else {
    val_gender = gender

    if (!val_gender == 'female') {
      res_gender = 0.2196434
    } else {
      res_gender = 0
    }
  }
  sum_score += res_gender

  // Extracardiac arteriopathy - Peripheral arterial disease
  let val_eca = 0
  let res_eca = 0

  if (!status_preop_peripheral_arterial_disease) {
    res_eca = 0
  } else {
    val_eca = status_preop_peripheral_arterial_disease

    switch (val_eca) {
      case 'yes':
        res_eca = 0.5360268
        break
      default:
        res_eca = 0
    }
  }
  sum_score += res_eca

  // CPD - chronic pulmonary disfuction - same as COPD
  let val_copd = 0
  let res_copd = 0

  if (!status_preop_copd) {
    res_copd = 0
  } else {
    val_copd = status_preop_copd

    switch (val_copd) {
      case 'yes':
        res_copd = 0.1886564
        break
      default:
        res_copd = 0
    }
  }
  sum_score += res_copd

  // N/M mob - nerological or musculoskeletal dysfunction severely affecting mobility  - same as poor mobility
  let val_poor_mobility = 0
  let res_poor_mobility = 0

  if (!risk_scores_preop_EuroSCORE_II_poor_mobility) {
    res_poor_mobility = 0
  } else {
    val_poor_mobility = risk_scores_preop_EuroSCORE_II_poor_mobility

    switch (val_poor_mobility) {
      case 'yes':
        res_poor_mobility = 0.2407181
        break
      default:
        res_poor_mobility = 0
    }
  }
  sum_score += res_poor_mobility

  // Redo - previous cardiac surgery
  let val_redo = 0
  let res_redo = 0

  if (!previous_cardiac_surgery) {
    res_redo = 0
  } else {
    val_redo = previous_cardiac_surgery

    switch (val_redo) {
      case 'yes':
        res_redo = 1.118599
        break
      case 'no':
        res_redo = 0
        break
      default:
        res_redo = 0
    }
  }
  sum_score += res_redo

  // Renal dysfuction -- CC - Creatine clearance
  let val_renal = 0
  let res_renal = 0

  if (!status_preop_renal_failure_grade) {
    res_renal = 0
  } else {
    val_renal = status_preop_renal_failure_grade

    switch (val_renal) {
      case 'severe GFR<50':
        res_renal = 0.8592256
        break
      case 'moderate GFR 50-85':
        res_renal = 0.303553
        break
      case 'on dialysis':
        res_renal = 0.6421508
        break
      default:
        res_renal = 0
    }
  }
  sum_score += res_renal

  // AE - active endocarditis
  let val_AE_tv = 0
  let val_AE_mv = 0
  let res_AE = 0

  // check TV
  if (!tv_disease_endocarditis_type) {
    val_AE_tv = 'no'
  } else {
    if (tv_disease_endocarditis_type == 'active') {
      val_AE_tv = 'yes'
    } else {
      val_AE_tv = 'no'
    }
  }

  // check AV
  if (!mv_disease_endocarditis_type) {
    val_AE_mv = 'no'
  } else {
    if (mv_disease_endocarditis_type == 'active') {
      val_AE_mv = 'yes'
    } else {
      val_AE_mv = 'no'
    }
  }

  if (val_AE_tv == 'yes' || val_AE_mv == 'yes') {
    res_AE = 0.6194522
  } else {
    res_AE = 0
  }
  sum_score += res_AE

  // Critical - critical preoperative state
  let val_critical = 0
  let res_critical = 0

  if (!risk_scores_preop_EuroSCORE_II_critical_preop_state) {
    res_critical = 0
  } else {
    val_critical = risk_scores_preop_EuroSCORE_II_critical_preop_state

    switch (val_critical) {
      case 'yes':
        res_critical = 1.086517
        break
      default:
        res_critical = 0
    }
  }
  sum_score += res_critical

  // LV function
  let val_LV = 0
  let res_LV = 0

  if (!risk_scores_preop_EuroSCORE_II_LV_function) {
    res_LV = 0
  } else {
    val_LV = risk_scores_preop_EuroSCORE_II_LV_function

    switch (val_LV) {
      case 'moderate':
        res_LV = 0.3150652
        break
      case 'poor':
        res_LV = 0.8084096
        break
      case 'very poor':
        res_LV = 0.9346919
        break
      default:
        res_LV = 0
    }
  }
  sum_score += res_LV

  // Recent MI - myocardial infarction
  let val_recent_MI = 0
  let res_recent_MI = 0

  if (!risk_scores_preop_EuroSCORE_II_recent_MI) {
    res_recent_MI = 0
  } else {
    val_recent_MI = risk_scores_preop_EuroSCORE_II_recent_MI

    switch (val_recent_MI) {
      case 'yes':
        res_recent_MI = 0.1528943
        break
      default:
        res_recent_MI = 0
    }
  }
  sum_score += res_recent_MI

  // PA systolic pressure
  let val_pa_systolic_pressure = 0
  let res_pa_systolic_pressure = 0

  if (!risk_scores_preop_EuroSCORE_II_PA_systolic_pressure) {
    res_pa_systolic_pressure = 0
  } else {
    val_pa_systolic_pressure = risk_scores_preop_EuroSCORE_II_PA_systolic_pressure

    switch (val_pa_systolic_pressure) {
      case '31-55 mmHg':
        res_pa_systolic_pressure = 0.1788899
        break
      case '>=55 mmHg':
        res_pa_systolic_pressure = 0.3491475
        break
      default:
        res_pa_systolic_pressure = 0
    }
  }
  sum_score += res_pa_systolic_pressure

  // Urgency
  let val_urgency = 0
  let res_urgency = 0

  if (!status_preop_urgency) {
    res_urgency = 0
  } else {
    val_urgency = status_preop_urgency

    switch (val_urgency) {
      case 'urgent':
        res_urgency = 0.3174673
        break
      case 'emergent':
        res_urgency = 0.7039121
        break
      case 'salvage':
        res_urgency = 1.362947
        break
      default:
        res_urgency = 0
    }
  }
  sum_score += res_urgency

  // Weight of procedure
  // intraop_op_tv, intraop_op_mv, intraop_concomitant_procedures
  let coef_weight = 0
  let val_weight = 0
  let res_weight = 0

  // check intraop_op_tv
  if (!intraop_op_tv) {
    res_weight += 0
  } else {
    val_weight = intraop_op_tv

    switch (val_weight) {
      case 'yes':
        res_weight += 1
        break
      default:
        res_weight += 0
    }
  }

  // check intraop_op_mv
  if (!intraop_op_mv) {
    res_weight += 0
  } else {
    val_weight = intraop_op_mv

    switch (val_weight) {
      case 'yes':
        res_weight += 1
        break
      default: 
        res_weight += 0
    }
  }

  // check intraop_concomitant_procedures
  if (!intraop_concomitant_procedures) {
    res_weight += 0
  } else {
    val_weight = intraop_concomitant_procedures

    switch (val_weight) {
      case 'yes':
        res_weight += 1
        break
      default:
        res_weight += 0
    }
  }

  coef_weight = 0

  switch (res_weight) {
    case 0:
      coef_weight = 0
      break
    case 1:
      coef_weight = 0.0062118
      break
    case 2:
      coef_weight = 0.5521478
      break
    case 3:
      coef_weight = 0.9724533
      break
  }

  
  sum_score += coef_weight // NOTE not res_ but coef_ is used here. different from the rest of this function

  // Thoraic aorta
  let val_thoraic_aorta = 0
  let res_thoraic_aorta = 0

  if (!risk_scores_preop_EuroSCORE_II_surgery_thoraic_aorta) {
    res_thoraic_aorta = 0
  } else {
    val_thoraic_aorta = risk_scores_preop_EuroSCORE_II_surgery_thoraic_aorta

    switch (val_thoraic_aorta) {
      case 'yes':
        res_thoraic_aorta = 0.6527205
        break
      default:
        res_thoraic_aorta = 0
    }
  }
  sum_score += res_thoraic_aorta

  // Constant
  sum_score += -5.324537

  // the probability is the mortality risk, which is the EuroSCORE II
  let mortality_risk = Math.exp(sum_score) / (1 + Math.exp(sum_score))

  return mortality_risk.toFixed(2)
}